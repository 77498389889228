const state = {
  pp: null,
  documents: [],
};

const getters = {
  documents: (state) => state.documents,
};

const actions = {
  LOAD_PAP(context, { papId, refresh = false }) {
    if (!refresh && context.state.pp && context.state.pp.id === papId) {
      return new Promise((resolve) => resolve(context.state.pp));
    }
    const config = {
      params:
          { includes: ['proxy_of', 'proxied_by'] },
    };
    return this.$axios
      .get(`/api/partiePrenante/data/${papId}`, config)
      .then((r) => {
        context.commit('SET_PAP', r.data);
        return r.data;
      });
  },
  SAVE_PAP(context, pap) {
    pap.project_id = context.rootState.app.selectedProject.id;
    return this.$axios.post(`/api/partiePrenante/update/${pap.id}`, pap)
      .then((result) => result.data);
  },
  ADD_PAP(context, pap) {
    pap.project_id = context.rootState.app.selectedProject.id;
    return this.$axios.put('/api/partiePrenante/create', { pap })
      .then((result) => result.data);
  },
  ADD_BIENS(context, {
    papid, role, parcelle, biens,
  }) {
    return this.$axios.post('/api/bien/create-list', {
      papId: papid,
      parcelle: parcelle,
      role: role,
      biens: biens,
    }).then((r) => r.data);
  },
  ADD_PROXY(context, {
    ppId, proxyId,
  }) {
    return this.$axios.post(`/api/partiePrenante/${ppId}/proxy/${proxyId}`)
      .then((r) => r.data);
  },
  DELETE_PROXY(context, {
    ppId, proxyId,
  }) {
    return this.$axios.delete(`/api/partiePrenante/${ppId}/proxy/${proxyId}`);
  },
  CREATE_FDB(context, data) {
    return this.$axios
      .post(
        `/api/partiePrenante/${data.papid}/document/${data.type}`,
        data.payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
  },
  LOAD_FDB(context, { id, includes }) {
    return this.$axios.get(`/api/fdbs/${id}`, { params: { includes: includes } })
      .then((r) => r.data);
  },
  SIGN_FDB(context, data) {
    return this.$axios.post(
      `/api/partiePrenante/sign_fdb/${data.fdbId}`,
      data.payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  SIGN_CONTRAT(context, data) {
    return this.$axios.post(
      `/api/partiePrenante/sign_contrat/${data.fdbId}`,
      data.payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  DOCUMENT_LIST_FROM_PP(context, { pp_id }) {
    return this.$axios.get(`/api/document/partiePrenante/${pp_id}`)
      .then((result) => {
        context.commit('SET_DOCUMENTS', result.data);
        return result.data;
      });
  },
  REMOVE_PP(context, pp_id) {
    return this.$axios.delete(`/api/partiePrenante/${pp_id}`);
  },
};

const mutations = {
  SET_PAP(s, pp) {
    s.pp = pp;
  },
  SET_DOCUMENTS(s, documents) {
    s.documents = documents;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
